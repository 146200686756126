<template>
  <div>
    <b-form @submit.prevent>
      <b-row
        class="d-sm-flex justify-content-center align-content-center mx-auto"
      >
        <b-card class="col-12">
          <b-card-body class="my-0 mx-auto">
            <b-row
              class="d-sm-flex justify-content-center align-items-end mx-auto"
            >
              <b-col cols="12" md="6" sm="9">
                <label>{{
                  $t(
                    "Pages.Tools.Subscribe Link Generator.Title.enter_youtube"
                  )
                }}</label>
                <input
                  type="text"
                  v-model="link"
                  dir="ltr"
                  class="w-100 form-control"
                />
              </b-col>
              <b-col
                cols="12"
                md="2"
                sm="3"
                class="mb-md-0 mt-sm-0 mt-2 text-sm-left text-center"
              >
                <input
                  type="submit"
                  class="btn btn-primary  text-center"
                  :value="
                    $t(
                      'Pages.Tools.Subscribe Link Generator.Button.generate_link'
                    )
                  "
                  @click="generate"
                />
              </b-col>
            </b-row>
            <div v-if="badResponse" class="d-flex justify-content-center mt-3">
              <b-alert show variant="danger">
                {{
                  $t(
                    "Pages.Tools.Subscribe Link Generator.Title.wrong_link"
                  )
                }}
              </b-alert>
            </div>
          </b-card-body>
        </b-card>

        <b-card class="col-12" v-if="subscribe_link">
          <p class="text-center mb-2" style="font-size: 16px">
            {{
              $t(
                "Pages.Tools.Subscribe Link Generator.Title.success_gen_link"
              )
            }}
          </p>
          <p class="d-flex justify-content-center mb-0 w-100">
            <b-alert show variant="primary" class="px-2 py-1 mb-0">
              <a class="text-muted" :href="subscribe_link" target="_blank">{{
                subscribe_link
              }}</a>
              <b-button
                active
                variant="primary"
                size="sm"
                class="mx-1"
                @click="copyLink"
              >
                <feather-icon icon="CopyIcon" size="16" />
              </b-button>
            </b-alert>
          </p>
        </b-card>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BCard,
  BAlert,
  BRow,
  BCol,
  BLink,
  BButton,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BCardBody
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BAlert,
    BLink,
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    BImg,
    BCardBody
  },
  data() {
    return {
      badResponse: null,
      link: "",
      subscribe_link: null,
    };
  },

  methods: {
    generate() {
      if (this.link.includes("youtube.com")) {
        this.badResponse = false;
        this.subscribe_link = this.link + "?sub_confirmation=1";
      } else {
        this.badResponse = true;
        this.subscribe_link = null;
      }
    },
    copyLink() {
      navigator.clipboard.writeText(`${this.subscribe_link}`);
      this.makeToast(
        this.$t("Base.Alert.Successful"),
        this.$t(
          "Base.Alert.success_copy_link"
        ),
        "success"
      );
    },
  },
};
</script>
